
import {
  Component,
  Vue
} from 'vue-property-decorator'
import ScheduleService from '../../services/schedule-service'
import {
  ToastModule
} from '@/store/modules/ToastModule'
import {
  AuthModule
} from '@/store/modules/AuthModule'
import BargeService from '../../services/barge-service'
import DateHelper from '@/mixins/date-mixins'
import ItemService from '../../services/item-service'
  @Component({
    name: 'BargeHire'
  })
export default class BargeHire extends Vue {
    protected isTableBusy = false
    protected jobs: any = []
    protected job: any = {}
    protected notes: any = []
    protected contentTypes: any = []

    protected dispatch: any = {
      id: null,
      dispatch_date: null,
      dispatch_quantity: null
    }

    protected fields: any = [{
      key: 'index',
      label: '#',
      thStyle: {
        width: '2%'
      },
      class: 'text-right'
    },
    {
      key: 'vehicle_number',
      label: 'Vehicle No.',
      thStyle: {
        width: '10%'
      }
    },
    {
      key: 'vehicle_capacity',
      label: 'Capacity',
      thStyle: {
        width: '10%'
      }
    },
    {
      key: 'content_type',
      label: 'Content',
      thStyle: {
        width: '10%'
      }
    },
    {
      key: 'total_tonnage',
      label: 'Tonnage',
      thStyle: {
        width: '10%'
      }
    },
    {
      key: 'dispatch_note_number',
      label: 'Note No.',
      thStyle: {
        width: '10%'
      }
    },
    {
      key: 'action',
      label: '',
      thStyle: {
        width: '10%'
      },
      class: 'text-center'
    }
    ]

    created () {
      this.boot()
    }

    protected boot () {
      this.populateScheduledJobs()
      this.populateContentTypes()
    }

    protected isNumber ($event: any) {
      const charCode = ($event.keyCode ? $event.keyCode : $event.which)
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        $event.preventDefault()
      } else {
        return true
      }
    }

    protected addLine () {
      this.notes.push({
        id: null,
        dispatch_id: null,
        vehicle_type: 'Bowser',
        vehicle_number: null,
        vehicle_capacity: null,
        content: {},
        total_tonnage: null,
        created_user: AuthModule.user.fullname,
        modified_user: AuthModule.user.fullname
      })
    }

    protected save () {
      const dispatch: any = {
        dispatch_type: 'Barge Hire',
        from_location_id: this.job.portId,
        from_location: this.job.port,
        dispatch_date: DateHelper.today('dd/mm/yyyy'),
        dispatch_quantity: this.dispatch.dispatch_quantity,
        status: 'completed',
        created_user: AuthModule.user.fullname,
        modified_user: AuthModule.user.fullname
      }
      const notes: any = []
      if (this.notes.length !== 0) {
        this.notes.forEach((note, index) => {
          notes.push({
            id: note.id,
            dispatch_note_number: index + 1,
            vehicle_type: 'Bowser',
            vehicle_number: note.vehicle_number,
            vehicle_capacity: note.vehicle_capacity,
            item_id: note.content.id,
            content_type: note.content.name,
            total_tonnage: note.total_tonnage,
            status: 'dispatched',
            line: {
              job_id: this.job.id,
              job_number: this.job.number,
              barge_id: null,
              barge_name: null,
              tonnage: note.total_tonnage,
              created_user: AuthModule.user.fullname,
              modified_user: AuthModule.user.fullname
            },
            created_user: AuthModule.user.fullname,
            modified_user: AuthModule.user.fullname
          })
        })
      }
      BargeService.save(dispatch, notes).then((response) => {
        ToastModule.message(response.data.message)
        this.reset()
        this.populateScheduledJobs()
      }).catch(error => {
        ToastModule.message(error.response.data.message)
      })
    }

    protected deleteDispatchNote (tableIndex: number, item: any) {
      if (item.id === null) this.notes.splice(tableIndex, 1)
    }

    protected async populateScheduledJobs () {
      const response = await ScheduleService.getScheduledJobs()
      response.data.forEach((element) => {
        this.jobs.push({
          value: {
            id: element.job_id,
            number: element.job_number,
            portId: element.port_id,
            port: element.port_name
          },
          text: element.job_number
        })
      })
    }

    protected populateContentTypes () {
      ItemService.getInventoryContentTypes().then((response) => {
        response.data.forEach((item) => {
          this.contentTypes.push({
            value: {
              id: item.id,
              name: item.display_name
            },
            text: item.display_name
          })
        })
      })
    }

    protected reset () {
      this.jobs = []
      this.job = {}
      this.dispatch = {}
      this.notes = []
    }
}

