import APIService from '@/services/api-service'
const resource = '/dispatch'

class BargeHireService extends APIService {
  public constructor () {
    super()
    this.mountAuthorizationToken()
  }

  public save (dispatch: any, notes: any) {
    return this.connector.post(`${resource}/barge-hire/save`, { dispatch: dispatch, notes: notes })
  }
}

export default new BargeHireService()
